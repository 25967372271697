<template>
    <v-container fluid>
        <v-form @submit.prevent="saveProfile">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{ heading }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="2" class="mt-2 px-sm-6 text-center">
                                <make-avatar v-model="photo" :aspect-ratio="1 / 1"/>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="9" md="10">
                                <v-row>
                                    <v-col class="py-0" cols="12" sm="7">
                                        <ValidationProvider ref="name" rules="required|min:3|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="name" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-account"
                                                          :label="$t('administrator_name')"
                                                          color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="5">
                                        <ValidationProvider ref="email" rules="required|email|min:8"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="email" type="email"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-email"
                                                          label="Email" color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="phone" rules="phone"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="phoneRaw" type="tel"
                                                          v-mask="phoneMask" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-phone"
                                                          :label="$t('phone')" color="primary"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="int_phone" rules="numeric|min:3|max:4"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="int_phone" type="tel"
                                                          :error-messages="errors" :disabled="loading"
                                                          prepend-icon="mdi-phone" :label="$t('int_phone')"
                                                          color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="country" rules="min:1"
                                                            v-slot="{ errors, valid }"
                                        >
                                            <v-autocomplete v-model="country" :items="countryItems"
                                                            :error="!valid" :error-messages="errors"
                                                            :search-input.sync="countrySearching"
                                                            item-text="name" item-value="id"
                                                            prepend-icon="mdi-web" :loading="loadingCountries"
                                                            :disabled="loading" @click="clearCountries"
                                                            :no-data-text="countrySearching? $t('nothing_found_by', {search: countrySearching}) : $t('nothing_found_country_name')"
                                                            :label="$t('country')" @click:clear="countryItems = []"
                                                            color="primary" return-objectclearable autocomplete="off">
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="city" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="city" :items="cityItems"
                                                            :error="!valid" :error-messages="errors"
                                                            :search-input.sync=" citySearching "
                                                            item-text="name" item-value="id" prepend-icon="mdi-city"
                                                            :loading="loadingCities" :disabled="loading || !country"
                                                            :no-data-text="citySearching ? $t('nothing_found_by',{earch: citySearching}) : $t('nothing_found_city_name')"
                                                            :label="$t('city')" @click:clear="cityItems = []"
                                                            @click="clearCities" color="primary" return-object
                                                            clearableautocomplete="off">
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="role" rules="required"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="role" :items="roleItems"
                                                      :error="!valid" :error-messages="errors"
                                                      :disabled="loading" item-text="title"
                                                      item-value="id" prepend-icon="mdi-account-hard-hat"
                                                      color="primary" :label="$t('role')"
                                            ></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <v-subheader class="headline">{{ $t("administrator_access_password") }}
                                </v-subheader>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="password" name="password"
                                                    :rules="form_required + 'min:8'" vid="password"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="password" prepend-icon="mdi-lock"
                                                  :error="!valid && create" :error-messages="errors"
                                                  :disabled="loading"
                                                  :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                                  :type="passwordHidden ? 'password' : 'text'"
                                                  @click:append="() => (passwordHidden = !passwordHidden)"
                                                  :label="$t('enter_password')"
                                                  color="primary" clearable required>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="confirmation_password"
                                                    name="confirmation_password"
                                                    rules="required_if:password|confirmed:password"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="password_confirmation"
                                                  :error="!valid"
                                                  :disabled="loading"
                                                  :append-icon="passwordConfirmHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                                  :type="passwordHidden ? 'password' : 'text'"
                                                  :error-messages="errors"
                                                  @click:append="() => (passwordConfirmHidden = !passwordConfirmHidden)"
                                                  prepend-icon="mdi-lock" :label="$t('repeat_password')"
                                                  color="primary" clearable required>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0 d-flex justify-start" cols="12" sm="6">
                                <v-switch v-model="deleted" :disabled="loading"
                                          :label="deleted ? $t('administrator_deleted') : $t('administrator_remove')"
                                          @change="deleted ? (active = false) : active"
                                          color="red" hide-details>
                                </v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="6">
                                <v-switch v-model="active" :disabled="loading || deleted"
                                          :label="$t('administrator_active')"
                                          color="primary" hide-details>
                                </v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pt-8">
                        <v-progress-linear v-model="progress" :active="loading" class="mx-2"/>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading"
                               :loading="loading"  color="primary">
                            {{ $t("save") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>
import debounce from "lodash/debounce"
import {ValidationObserver, ValidationProvider} from "vee-validate"
import {mask} from "vue-the-mask"
import {mapActions, mapGetters} from "vuex"
import MakeAvatar from "../components/MakeAvatar.vue"

export default {
    name: "AdministratorForm",
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        MakeAvatar,
    },
    inject: ["forceRerender"],
    data() {
        return {
            heading: null,
            progress: 0,
            create: false,
            phoneRaw: null,
            int_phone: null,
            id: null,
            name: null,
            email: null,
            active: false,
            deleted: false,
            password: null,
            passwordHidden: true,
            password_confirmation: null,
            passwordConfirmHidden: true,
            loading: false,
            loadingCities: false,
            loadingCountries: false,
            photo: null,
            role: null,
            roleItems: [],
            country: null,
            countryItems: [],
            countrySearching: null,
            city: null,
            cityItems: [],
            citySearching: null,
        };
    },
    computed: {
        ...mapGetters(["lang", "phoneMask", "phoneIntMask", "defaultAvatar"]),
        phone: function () {
            return this.changePhone(this.phoneRaw);
        },
        form_required: function () {
            if (
                this.$route.name === "administrator.edit" ||
                (this.$route.name === "profile" && this.$auth.check())
            ) {
                return "";
            }
            return "required|";
        },
    },
    watch: {
        countrySearching: debounce(function (val) {
            if (val && !this.country) {
                this.getCountries(val);
            }
        }, 500),
        citySearching: debounce(function (val) {
            if (val && this.country && !this.city) {
                this.getCities(val);
            }
        }, 500),
        password(val) {
            if (val && val !== this.password_confirmation) {
                this.$refs.confirmation_password.setErrors([
                    this.$t("validations.rules.confirmed", {
                        _field_: this.$t(
                            "validations.fields.confirmation_password"
                        ),
                    }),
                ]);
            }
        },
        password_confirmation(val) {
            if (val && val !== this.password) {
                this.$refs.password.setErrors([
                    this.$t("validations.rules.confirmed", {
                        _field_: this.$t("validations.fields.password"),
                    }),
                ]);
            } else {
                this.$refs.password.reset();
            }
        },
    },
    mounted() {
        this.getRoles();
        this.checkCreate();
    },
    methods: {
        ...mapActions(["setUserName", "setUserPosition", "setUserAvatar"]),
        checkCreate() {
            if (this.$route.name === "administrator.create") {
                this.create = true;
                this.heading = this.$t("administrator_creation");
            } else if (this.$route.name === "profile" && this.$auth.check()) {
                this.heading = this.$t("administrator_editing");
                if (
                    this.$auth.check() &&
                    this.$auth.user() &&
                    this.$auth.user().id
                ) {
                    this.getProfile(this.$auth.user().id);
                }
            } else {
                this.heading = this.$t("administrator_editing");
                if (this.$route.params.id) {
                    this.getProfile(this.$route.params.id);
                }
            }
        },
        clearCountries() {
            if (!this.country) {
                this.countryItems = [];
                this.city = null;
                this.cityItems = [];
            }
        },
        clearCities() {
            if (!this.city) {
                this.cityItems = [];
            }
        },
        async getRoles() {
            let params = {};
            params.filter = "getroles";
            await this.$http
                .get(`admin/role`, {
                    params: params,
                })
                .then((res) => {
                    this.roleItems = res.body.data;
                })
                .catch((err) => {
                    this.roleItems = [];
                    this.$toastr.error(this.$t("failed_to_get_list_roles"));
                });
        },
        async getCities(str) {
            if (str) {
                this.loadingCities = true;
                let params = {};
                params.filter = "search";
                if (str !== "undefined") {
                    params.city = str;
                }
                if (this.country) {
                    if (this.country.id) {
                        params.country = this.country.id;
                    } else {
                        params.country = this.country;
                    }
                }
                await this.$http
                    .get("admin/city", {
                        params: params,
                    })
                    .then((res) => {
                        this.cityItems = res.body.data;
                    })
                    .catch((err) => {
                        this.cityItems = [];
                        this.$toastr.error(
                            this.$t("failed_to_get_list_cities")
                        );
                    })
                    .finally((end) => {
                        this.loadingCities = false;
                    });
            }
        },
        async getCountries(str) {
            if (str) {
                this.loadingCountries = true;
                let params = {};
                params.filter = "search";
                if (str !== "undefined") {
                    params.country = str;
                }
                await this.$http
                    .get("admin/country", {
                        params: params,
                    })
                    .then((res) => {
                        this.countryItems = res.body.data;
                    })
                    .catch((err) => {
                        this.countryItems = [];
                        this.$toastr.error(
                            this.$t("failed_to_get_list_countries")
                        );
                    })
                    .finally((end) => {
                        this.loadingCountries = false;
                    });
            }
        },
        async getProfile(id) {
            var _this = this;
            this.progress = 0;
            this.loading = true;
            this.id = id;
            await this.$http
                .get(`admin/admin/${id}`, {
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round(
                                (e.loaded / e.total) * 100
                            );
                        }
                    },
                })
                .then((res) => {
                    this.name = res.body.data.name;
                    this.phoneRaw = res.body.data.phone;
                    this.int_phone = res.body.data.int_phone;
                    this.email = res.body.data.email;
                    this.photo = res.body.data.photo;
                    this.active = res.body.data.active;
                    this.deleted = res.body.data.deleted;
                    this.role = res.body.data.role;

                    let country = res.body.data.country;
                    if (country && country.id) {
                        this.country = country.id;
                        this.countryItems = [res.body.data.country];
                    } else {
                        this.countryItems = [];
                    }

                    let city = res.body.data.city;
                    if (city && city.id) {
                        this.city = city.id;
                        this.cityItems = [res.body.data.city];
                    } else {
                        this.cityItems = [];
                    }
                })
                .catch((err) => {
                    this.$toastr.error(this.$t("failed_to_get_administrator"));
                })
                .finally((end) => {
                    this.progress = 0;
                    this.loading = false;
                });
        },
        async saveProfile() {
            var _this = this;
            this.progress = 0;
            this.loading = true;
            var formData = new FormData();
            if (this.photo) {
                if (this.photo.length > 250) {
                    var mimeType = this.getMimeType(this.photo);
                    var blob = this.dataURL64toBlob(this.photo);
                    if (mimeType && blob) {
                        formData.append("photo", blob, mimeType);
                    }
                } else {
                    formData.append("photo", this.photo);
                }
            }
            if (this.name) {
                formData.append("name", this.name);
            }
            if (this.phone) {
                formData.append("phone", this.phone);
            }
            if (this.email) {
                formData.append("email", this.email);
            }
            if (this.role) {
                formData.append("role", this.role);
            }
            if (this.password) {
                formData.append("password", this.password);
            }
            if (this.int_phone) {
                formData.append("int_phone", this.int_phone);
            }
            if (this.active) {
                formData.append("active", 1);
            }
            if (this.deleted) {
                formData.append("deleted", 1)
            }
            if (this.country) {
                if (this.country.id) {
                    formData.append("country", this.country.id)
                } else {
                    formData.append("country", this.country)
                }
            }
            if (this.city) {
                if (this.city.id) {
                    formData.append("city", this.city.id)
                } else {
                    formData.append("city", this.city)
                }
            }
            if (this.id) {
                // Save
                await this.$http
                    .put(`admin/admin/${this.id}`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round(
                                    (e.loaded / e.total) * 100
                                )
                            }
                        },
                    })
                    .then((res) => {
                        this.$toastr.success(
                            this.$t("administrator_has_been_updated")
                        )
                        if ((this.$route.params.id && this.$route.params.id === this.$auth.user().id) ||
                            (this.$route.name === "profile" && this.$auth.check())) {
                            this.$auth.fetch().then((res) => {
                                this.setUserName(this.$auth.user().name)
                                this.setUserPosition(
                                    this.$auth.user().position
                                )
                                this.setUserAvatar(
                                    this.$auth.user().photo
                                        ? this.$auth.user().photo
                                        : this.defaultAvatar
                                )
                            })
                        }
                        //this.forceRerender()
                    })
                    .catch((err) => {
                        this.$toastr.error(
                            this.$t("administrator_has_not_been_updated")
                        )
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (
                                    hasOwnProperty.call(err.body.errors, prop)
                                ) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0],
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally((end) => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post("admin/admin", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round(
                                    (e.loaded / e.total) * 100
                                )
                            }
                        },
                    })
                    .then((res) => {
                        this.$toastr.success(
                            this.$t("administrator_has_been_added")
                        )
                        this.$router.push({
                            name: "administrator",
                        })
                        //this.forceRerender()
                    })
                    .catch((err) => {
                        this.$toastr.error(
                            this.$t("administrator_has_not_been_added")
                        )
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (
                                    hasOwnProperty.call(err.body.errors, prop)
                                ) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0],
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally((end) => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        }
    }
}
</script>
